<template>
  <div>
    <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
      <a-divider orientation="left">
        <b>{{ formTitle }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules">

        <a-form-model-item label="分组名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入分组名称"/>
        </a-form-model-item>

        <a-form-model-item label="开始时间" prop="startTime">
          <a-date-picker style="width: 100%" @change="onDateChange" :showTime="true" format="YYYY-MM-DD HH:mm:ss"
                         allow-clear/>
        </a-form-model-item>

        <a-form-model-item label="结束时间" prop="endTime">
          <!--        <a-date-picker style="width: 100%" v-model="form.endTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
          <a-date-picker style="width: 100%" @change="onDateChange1" :showTime="true" format="YYYY-MM-DD HH:mm:ss"
                         allow-clear/>
        </a-form-model-item>

        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              保存
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
import {getBanner, addBanner, updateBanner} from '@/api/config/bannerGroup'
import {mapGetters} from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        name: null,

        startTime: null,

        endTime: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          {required: true, message: '广告名称不能为空', trigger: 'blur'}
        ],

        startTime: [
          {required: true, message: '开始时间不能为空', trigger: 'blur'}
        ],

        endTime: [
          {required: true, message: '结束时间不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,

        name: null,

        startTime: null,

        endTime: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    onDateChange(date, dateString) {
      console.log(date, dateString)
      this.form.startTime = dateString
    },
    onDateChange1(date, dateString) {
      console.log(date, dateString)
      this.form.endTime = dateString
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBanner(id).then(response => {
        Object.assign(this.form, response.data)
        this.open = true
        this.formTitle = '修改'

        // 处理图片反显
        if (response.data.smallImg !== undefined && response.data.smallImg !== null && JSON.stringify(response.data.smallImg) !== '"{}"') {
          var images = this.form.smallImg.split(',')
          for (var i = 0; i < images.length; i++) {
            this.fileImgList.push({status: 'done', url: images[i], uid: this.getUidRandom(), name: 'smallImg.jpg'})
          }
        }
        if (response.data.audio !== undefined && response.data.audio !== null) {
          var videos = this.form.audio.split(',')
          for (var e = 0; e < videos.length; e++) {
            this.talkInfoVideo.push({status: 'done', url: videos[e], uid: this.getUidRandom(), name: 'audio.mp4'})
          }
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBanner(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBanner(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
  }
}
</script>
