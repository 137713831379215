import request from '@/utils/request'

// 查询banner配置列表
export function listBanner(query) {
  return request({
    url: '/config/banner/group/list',
    method: 'get',
    params: query
  })
}

// 查询banner配置详细
export function getBanner(id) {
  return request({
    url: '/config/banner/group/' + id,
    method: 'get'
  })
}

// 新增banner配置
export function addBanner(data) {
  return request({
    url: '/config/banner/group',
    method: 'post',
    data: data
  })
}

// 修改banner配置
export function updateBanner(data) {
  return request({
    url: '/config/banner/group',
    method: 'put',
    data: data
  })
}

// 删除banner配置
export function delBanner(id) {
  return request({
    url: '/config/banner/group/' + id,
    method: 'delete'
  })
}
